




















































































import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import { ApiResponse, User } from '@/interfaces'
import NavMenuMain from '@/components/NavMenuMain.vue'
import NavMenuLogin from '@/components/NavMenuLogin.vue'

export default Vue.extend({
  components: { NavMenuMain, NavMenuLogin },
  data() {
    return {
      userInfos: {
        firstName: null,
      },
      activeIndex: 'dashboard',
      showConnectAsDialog: false,
      email: null as null | string,
      users: [] as User[],
      hasInventoryRole: false,
    }
  },
  computed: {
    ...mapState(['user', 'connectedAs', 'invoicesFilters']),
    connectedAsEmail(): string | null {
      return this.connectedAs?.email
    },
  },
  watch: {
    user(newVal) {
      this.userInfos = newVal
      this.hasInventoryRole = newVal && newVal.roles.includes('ROLE_INVENTORY')
    },
    invoicesFilters(newVal) {
      if (
        this.invoicesFilters.previousRoute?.includes('accounting') ||
        this.invoicesFilters.previousRoute?.includes('payments')
      ) {
        this.setInvoicesFilters(newVal)
      }
    },
  },
  mounted() {
    if (this.user) {
      this.userInfos = this.user
      this.hasInventoryRole = this.user && this.user?.roles.includes('ROLE_INVENTORY')
    }

    if (!this.connectedAs) {
      delete this.$api.defaults.headers.common['x-switch-user']
    }

    if (
      this.$route.name?.includes('accounting') ||
      this.$route.name?.includes('payments') ||
      this.$route.name?.includes('invoice_')
    ) {
      if (
        this.invoicesFilters &&
        (this.invoicesFilters.previousRoute?.includes('accounting') ||
          this.invoicesFilters.previousRoute?.includes('payments') ||
          this.invoicesFilters.previousRoute?.includes('invoice_'))
      ) {
        this.setInvoicesFilters(this.invoicesFilters)
      } else {
        this.setInvoicesFilters({})
      }
    } else {
      this.setInvoicesFilters({})
    }
  },
  methods: {
    ...mapActions(['setConnectedAs', 'loadAppData', 'setInvoicesFilters']),
    handleSelect(key: string) {
      if (this.$route.name !== key) {
        this.$router.push({ name: key })
      }
    },
    connectAsUser() {
      if (this.email !== null && this.email !== '') {
        this.$api.defaults.headers.common['x-switch-user'] = this.email
        this.loadAppData().then((response) => {
          this.showConnectAsDialog = false
          if (response.status == 403) {
            if (response.data?.detail) {
              if (response.data.detail.match(/\bUser "\b[a-z@.]+" not found/)) {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message: 'Erreur : Utilisateur non trouvé ou inexistant.',
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message: "Erreur : Vous n'avez pas les droits nécessaires pour effectuer cette opération.",
                })
              }
            }
          } else {
            this.setConnectedAs(response.data)
            this.$router.push({ name: 'dashboard' })
          }

          this.email = null
        })
      }
    },
    disconnectAsUser() {
      delete this.$api.defaults.headers.common['x-switch-user']
      this.loadAppData().then(() => {
        this.setConnectedAs(null)
        this.email = null
        this.$router.push({ name: 'dashboard' })
      })
    },
    searchUsers(query: string) {
      this.$api
        .get('/users', { params: { search: query } })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.users = apiResponse.data
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
    },
    formatSearchedUser(user: User) {
      return `${user.email} - ${user.formName}`
    },
    goToDeletedProjectList() {
      this.$router.push({ name: 'deleted_projects_list' })
    },
    toggleMobileNav() {
      const mobileNav = document.getElementById('mobileNav')

      mobileNav?.classList.toggle('active')
    },
    showConnectAsDialogAction() {
      this.showConnectAsDialog = true

      const mobileNav = document.getElementById('mobileNav')

      mobileNav?.classList.remove('active')
    },
  },
})
